*{
    font-family: 'Uniform', sans-serif;
}
.container1{
    width: calc(100vw-17rem);
    height: 100vh;
    background-color: #FAF9FF;
}
.dashboard-container{
    display: grid;
    grid-template-columns:  2fr 1fr 1fr;
    grid-template-rows: 1fr 5fr 6fr 8fr 33fr 11fr 6fr 33fr 6fr;
    gap: 10px;
    
    height: 100vh;
    /*width: calc(100vw-19rem);*/
}
.grid-empty1{
    grid-column: 1 /-1;
    grid-row: 1;
}
.dashboard-title{
    font-family: "Uniform", sans-serif;
    grid-column: 1 /-1;
    grid-row: 2;
    font-weight: bold;
    font-size: 36px;
    color: #000000;
}
.dashboard-welcome{
    grid-column: 1 /-1 ;
    grid-row: 3;
    font-family: "Uniform", sans-serif;
    font-size: 500;
    color: #737373;    

}
.grid-empty2{
    grid-column: 1 /-1;
    grid-row: 4;
}
.grid-first-cards{
    display: flex;
    width: 100%;
    gap: 10px;
    grid-row: 5;
}
.grid-empty3{
    grid-column: 1 /-1 ;
    grid-row: 6;
}
.grid-second-title{
    grid-column: 1 /-1 ;
    grid-row: 7;
    color: #000;
    font-size: 24;
    font-weight: bold;
    line-height: auto;
    text-align:start;
    text-align: left;
    height: 36;
}
.grid-second-cards{
    display: flex;
    gap: 10px;
    grid-row: 8;
}
.grid-item4{
    grid-column: 1;
    grid-row: 8;
}
.grid-item5{
    grid-column: 2;
    grid-row: 8;
}
.grid-item6{
    grid-column: 3;
    grid-row: 8;
}
.grid-empty4{
    grid-column: 1 /-1 ;
    grid-row: 9;
}

/**------------------BUILDING PERMIT STYLES START*/
.building-permit-form {
    padding: 20px;
    margin-left: 17rem;
  }
  
  .building-permit-form h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  .building-permit-form input{
    width: 100%;
  }

  .tab-content {
    margin-top: 10px;
  }
  
  .building-permit-form .MuiTextField-root {
    margin-bottom: 5px;
  }
  
  .building-permit-form .MuiFormControl-root {
    margin-bottom: 5px;
  }
  
  .building-permit-form .MuiButton-contained {
    margin-top: 10px;
  }
  
  /* Responsive Styling */
  @media (max-width: 600px) {
    .building-permit-form .MuiGrid-container {
      flex-direction: column;
    }
  
    .building-permit-form .MuiGrid-item {
      margin-bottom: 15px;
    }
  }
  /**------------------BUILDING PERMIT STYLES END*/
/* Make the grid vertical when it starts to be covered */
@media (max-width: 768px) {
    .dashboard-container{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: row;   
    }
  }