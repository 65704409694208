  .north-arrow {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
  }
  
  .legend-placeholder {
    position: absolute;
    bottom: 20px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border: 1px solid #000;
  }
  