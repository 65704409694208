html, body, #root, .app-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
.map-container {
    position: inherit;
    width: 100%;
    height: 100%;
  }
  .popup-container {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: white;
    padding: 10px;
    border: 1px solid black;
  }
  
  .popup h3 {
    margin-top: 0;
  }