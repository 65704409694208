*{
    font-family: 'Uniform', sans-serif;
    font-style: normal;
	font-size: 14px;
    }
:root {
	--primary: #238ebc;
	--neutral: rgba(128, 128, 128, 0.699);
	--secondary: #a1cc9c;
	--text-primary: navy;
}

body {
	margin: 0;
	font-family:
		'Uniform',
		'Open Sans',
		'sans-serif',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--secondary);
}

code {
	font-family: Uniform, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
* {
	color: var(--text-primary);
	margin: 0;
}
p {
	margin: 10px 0;
}
button {
	border: 0;
	padding: 8px;
	border-radius: 10px;
	color: white;
	background: var(--primary);
	cursor: pointer;
	font-weight: 400;
}

/* navbar */
/*header nav {
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	max-width: 1200px;
	margin: 0 auto;
}
header nav h1 {
	margin-right: 220px;
	border-bottom: 3px solid var(--primary);
}
header nav a {
	text-decoration: none;
	padding: 6px;
	border-radius: 4px;
}
header nav a.active {
	background: var(--primary);
}

main {
	max-width: 1200px;
	margin: 40px auto;
}*/
/* contact form */
/*form {
	margin-top: 10px;
	border-radius: 15px 50px 30px 5px;
}*/
form input,
form label span,
form textarea {
	display: block;
}
form input {
	height: auto;
	margin: 10px;
	padding: auto;
}
form textarea{
	margin: 10px;
}
form select {
	margin: 5px;
	margin-left: 10px;
	padding: auto;
	/* width: 300px;*/
	color: var(--secondary);
}
form label span {
	margin: 5px;
}
/* full screen for map*/
.full-screen {
	width: 100%;
	height: 100%;
}
/*avtar logo*/
/*.menu-container {
	position: relative;
}
.menu-header {
	display: flex;
	align-items: center;
}
.menu-logo {
	height: 40px;
	gap: 10px;
}
.menu-toggle {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 30px;
	background: none;
	border: none;
}*/
/*login page*/
.login-page {
	max-width: 400px;
	margin: 0 auto;
	padding: 20px;
}

.logo {
	display: flex;
	margin: 0 auto;
	margin-top: 50px;
	width: 100px;
	height: 100px;
	padding-bottom: 5px;
}

.login-page h1 {
	text-align: center;
	padding-bottom: 5px;
}

.login-page form {
	display: flex;
	flex-direction: column;
}
.login-page form select {
	display: block;
	padding-bottom: 5px;
}

.login-page label {
	margin-top: 10px;
	padding-bottom: 5px;
}

.login-page input {
	padding: 10px;
	margin-top: 5px;
	text-transform: none;
}

.login-page button {
	margin-top: 10px;
	padding: 10px;
}

.error {
	color: red;
	margin-top: 10px;
}
/*Registration form*/
.registration-page {
	max-width: 400px;
	margin: 0 auto;
	padding: 8px;
}

.registration-page h1 {
	text-align: center;
}

.registration-page form {
	display: flex;
	flex-direction: column;
}

.registration-page input,
.registration-page select,
.registration-page textarea {
	padding: 10px;
	margin-top: 4px;
}

.registration-page button {
	margin-top: 4px;
	padding: 4px;
}

.gender {
	display: flex;
	align-items: center;
	margin-left: 10px;
}

.gender span {
	margin-right: 10px;
}

.gender label {
	display: flex;
	align-items: center;
	margin-right: 40px;
	
}
.gender label input {
	display: flex;
	align-items: center;
	margin-top: 10px;
}
/* breadcrumbs */
.breadcrumbs {
	max-width: 1200px;
	margin: 20px auto;
}
.breadcrumbs > * {
	display: inline-block;
	margin-right: 10px;
}
.breadcrumbs .crumb:after {
	content: '>';
	margin-left: 10px;
}
.breadcrumbs .crumb:last-child:after {
	display: none;
}
.breadcrumbs .crumb a {
	color: #ddd;
}
@media print {
    .no-print {
      display: none !important;
    }
	.printable-paper {
		width: 100%;
		transform: scale(0.75); /* Adjust this value as needed */
		transform-origin: top left;
	  }
	
	  table {
		font-size: 1.5em; /* Adjust font size if needed */
	  }
	
	  th, td {
		padding: 5px;
	  }
  }
@media (max-width: 600px) {
	.menu {
		display: none;
		flex-direction: column;
		position: absolute;
		top: 60px;
		right: 0;
		background: white;
		border: 1px solid #ccc;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	}

	.menu.open {
		display: flex;
	}

	.menu li {
		margin-right: 0;
		padding: 10px;
	}
}
.assign-info {
	display: flex;
	width: 100%;
}
.assign-info .map-section {
	width: 80%;
}
.assign-info .form-section {
	width: 20%;
	padding: 20px;
	margin-left: 20px;
	background-color: grey;
}
@media (min-width: 601px) {
	.menu-toggle {
		display: none;
		background-color: var(--secondary);
	}
	.menu {
		display: flex;
	}
	.menu li {
		font-weight: bolder;
	}
}
